














import { Vue, Component, Prop } from 'vue-property-decorator';
import { Button } from '@/classes/base/elements';
@Component({})
export default class BaseButton extends Vue {
	@Prop({
		default: {
			text: '',
			color: 'gray-50',
			hoverColor: 'gray-600',
			textColor: 'gray-600',
			textHoverColor: 'white'
		}
	})
	button: Button;

	get buttonClass(): string {
		return 'bg-' + this.button.color + ' hover:bg-' + this.button.hoverColor + ' text-' + this.button.textColor + ' hover:text-' + this.button.textHoverColor;
	}
}
